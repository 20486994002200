.SocialIconsFooter {
    color: #ffffff;
}

.text-mp-mobile {
    color: #ffffff !important;
    margin: 0 auto !important;
}

@media (min-width: 768px) {
    .oculto-desktop {
        display: none;
    }
}

@media (max-width: 767px) {
    .oculto-mobile {
        display: none;
    }
}