.my-slider {
    display: flex;
    justify-content: space-between;
    overflow: visible;
    width: 100%;
 
  
    .slick-slide {
      margin-right: 0.1px;
    }
  
    .slick-slide:last-child {
      margin-right: 0;
    }

    .prev-arrow,
    .next-arrow {
    display: none;
}
}

.carousel-container .react-multiple-carousel__arrow {
  background-color: rgba(128, 128, 128, 0.1);
  border-radius: 50%;
  height: 24px; 
  width: 24px; 
  z-index: 900;
}

.carousel-container .react-multiple-carousel__arrow::before {
  color: #3A36DB;
  font-size: 20px;
  font-weight: bold;
}
