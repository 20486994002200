/* Position and sizing of burger button */
.bm-burger-button {
  position: fixed;
  width: 26px;
  height: 20px;
  left: 36px;
  top: 30px;
  @media (max-width: 768px) {
    top:30px;
    right: 36px;
    left: auto;
  }
}

/* Color/shape of burger icon bars */
.bm-burger-bars {
  background: #ffffff;
}

/* Color/shape of burger icon bars on hover*/
.bm-burger-bars-hover {
  background: #373a47;
}

/* Position and sizing of clickable cross button */
.bm-cross-button {
  height: 24px;
  width: 24px;
}

/* Color/shape of close button cross */
.bm-cross {
  background: #bdc3c7;
  left: -10px;
}

/*
Sidebar wrapper styles
Note: Beware of modifying this element as it can break the animations - you should not need to touch it in most cases
*/
.bm-menu-wrap {
  position: fixed;
  height: 100%;
  min-height: 100vh;
}

/* General sidebar styles */
.bm-menu {
  background: #ffffff;
  padding: 1rem 1rem 0;
  font-size: 1.15rem;

}

/* Morph shape necessary with bubble or elastic */
.bm-morph-shape {
  fill: #373a47;
}

.bm-item-list {
  color: #b8b7ad;
  padding: 0.3rem;
}

.bm-item span, svg {
  vertical-align: middle;
}


/* Styling of overlay */
.bm-overlay {
  background: rgba(0, 0, 0, 0.3);
}

.sidebar {
  /* Estilo del scrollbar */
  &::-webkit-scrollbar {
      width: 10px;
  }

  &::-webkit-scrollbar-track {
      background-color: #0000ff;
  }

  &::-webkit-scrollbar-thumb {
      background-color: #0000ff;
      border-radius: 10px;
  }

  &::-webkit-scrollbar-thumb:hover {
      background-color: #0000ff;
  }
}

