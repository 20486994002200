.swiper-button-next, .swiper-button-prev {
    background-color: #fff;
    color: #000;
    padding: 2px 0px;
    height: 30px;
    width: 30px;
    &:after {
        font-size: 14px;
    }

}

/* Estilos generales para los bullets */
.swiper-pagination-bullet {
    background-color: #fff; /* Color de fondo */
    opacity: 0.6; /* Opacidad inicial */
    width: 8px; /* Ancho del bullet */
    height: 8px; /* Altura del bullet */
    border-radius: 50%; /* Hace los bullets completamente redondos */
    margin: 0 4px; /* Espaciado horizontal entre bullets */
    margin-top: 20px;
}

/* Estilo para el bullet activo */
.swiper-pagination-bullet-active {
    opacity: 1; /* Opacidad completa para el bullet activo */
    background-color: #000; /* Color de fondo para el bullet activo */
}


/* Aumentar el tamaño del bullet activo */
.swiper-pagination-bullet-active {
    width: 12px; /* Ancho más grande */
    height: 12px; /* Altura más grande */
    background-color: #000; /* Puede ser cualquier color que prefieras */
}


.swiper-pagination {
    text-align: center; /* Centra los bullets horizontalmente *
    bottom: 10px; /* Posición desde la parte inferior del swiper */
}

.swiper-pagination-bullet {
    background: linear-gradient(45deg, rgba(255,0,0,1) 0%, rgba(255,154,0,1) 100%);
    box-shadow: 0px 0px 4px rgba(0, 0, 0, 0.5);
}
