.button-style {
    background-color:transparent;
    border-radius: 12px;
    height: 100%;
    border-right: 2px solid #3A36DB;
    overflow: hidden;
    border-top-right-radius: 0px;
    border-bottom-right-radius: 0px;

    
}

.flag-dropdown.open {
   width: 100%;
   border-radius: 20px;
}

.react-tel-input .flag-dropdown.open .selected-flag {
   border-radius: 20px;
}


.react-tel-input .selected-flag:hover {
   background-color: transparent;
}

.react-tel-input .flag-dropdown.open {
    border-radius: 20px;
}
