.popup-content {
  margin: auto;
  background: rgb(255, 255, 255);
  padding: 5px;
  width: 100%;
  z-index: 996 !important;
}
.popup-arrow {
  color: rgb(255, 255, 255);
}
[role="tooltip"].popup-content {
  width: 95%;
  box-shadow: rgba(0, 0, 0, 0.16) 0px 0px 3px;
}
@media only screen and (max-width: 768px) {
  [role="tooltip"].popup-content {
    width: 100%;
  }
}

.popup-overlay {
  background: rgba(0, 0, 0, 0.5);
}
[data-popup="tooltip"].popup-overlay {
  background: transparent;
}
