.my-slider {
  display: flex;
  justify-content: space-between;
  overflow: visible;
  width: 100%;
}
.my-slider .slick-slide {
  margin-right: 0.1px;
}
.my-slider .slick-slide:last-child {
  margin-right: 0;
}

.carousel-container .react-multiple-carousel__arrow {
  background-color: transparent;
  height: 24px;
  width: 24px;
  z-index: 900;
}

.carousel-container .react-multiple-carousel__arrow::before {
  color: #000 !important;
  font-size: 20px;
  font-weight: bold;
  background-color: rgba(255, 255, 255, 0.8);
}

.carousel-container {
  padding-bottom: 30px;
}

.carousel-container .react-multiple-carousel__arrow {
  background-color: transparent !important;
}/*# sourceMappingURL=styles.css.map */