.container {
  width: 100%;  
  padding: 0;
  max-width: none;
  margin: 0;
}

.input {
    width: 100%;
    height: 100%;
    border: none;
    background-color: transparent;
}



.input-picker {
    width: 100%;  
    height: 52px;
    border: 2px solid #3a36db;
    border-radius: 12px;
    padding: 6px;
    /* margin-bottom: 10px; */
}