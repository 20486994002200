body {
  margin: 0;

  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}
* {
  box-sizing: border-box;
}

#page-wrap {
  padding-bottom: 10px;
  padding-top: 10px;
}

/*Outfit*/
@font-face {
  font-family: "Outfit";
  src: url("./assets/fonts/Outfit.ttf") format("truetype");
}

@font-face {
  font-family: "Roboto";
  src: url("./assets/fonts/Roboto.ttf") format("truetype");
}

/*Montserrat*/
@font-face {
  font-family: "Montserrat";
  src: url("./assets/fonts/Montserrat.ttf") format("truetype");
}