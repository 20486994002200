.image-gallery-right-nav > svg {
    color: #fff;
    box-shadow: none;
}

.image-gallery-left-nav > svg {
    color: #fff;
}

.fullscreen > div {
    height: 100vh;
}

.fullscreen > div > div > div > div > div  {
    height: 100vh;
}

.fullscreen > div > div > div > div > div > img  {
    min-height: 95vh;
}


