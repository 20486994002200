.content-product img {
        height: auto;
        max-height: 500px;
        object-fit: contain;
        max-width: 80%;
}

@media screen and (max-width: 768px) {
        .content-product img {
                max-width: 100%;
        }
}

.content-product video {
        max-width: 100%!important;
        height: 100%;
}

.wp-video {
        width: 100%!important;
        height: 100%;
}

.GeNET {
        text-align: right;
        margin-right: 0;
}

.drVIXR {
        display: block !important;
}

.drVIXR a {
        text-decoration: none;
        display: flex !important;
        justify-content: flex-end !important;
}